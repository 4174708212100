import { useSelector } from "react-redux"
import AllPostsSkeleton from "components/public/BlogTheme/AllPostsSkeleton"
import { sortByDate } from "util/filter"
import { useAnalytics } from "hooks/useAnalytics"
import PublicLayout from "components/layouts/PublicLayout"
import { ReactElement, useEffect } from "react"
import { selectFilteredNotes, selectPinnedPosts } from "../features/noteSlice"
import { selectBlog } from "features/blogSlice"
import Head from "components/Head"
import { GetStaticProps } from "next"
import { getMetaDescription } from "@/util/note"
import useBlogUrl from "hooks/useBlogUrl"

import { handleBlogStaticProps } from "util/ssr/blogPage"
import { wrapper } from "store"
import { getShareImage } from "@/util/format"
import { FrameResponseGenerator } from "@/util/frames"
import consoleLogger from "@/util/console-logger"
import { RESPONSES } from "@/util/frames/types"

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(
  // @ts-ignore
  (store) => async (ctx) => {
    const { params } = ctx

    return await handleBlogStaticProps({
      store,
      blogName: params?.blogname as string,
    })
  }
)

export async function getStaticPaths() {
  // If the vercel environment is not production (ie, if it's staging or preview),
  // we don't want to generate the paths.
  // Disable static path generation for now.
  if (process.env.VERCEL_ENV !== "production" || true) {
    return {
      paths: [],
      fallback: "blocking",
    }
  }
}

function BlogPageComponent({
  pageNum,
  community,
  category,
}: {
  pageNum: number
  community: string
  category: string
}) {
  const blog = useSelector(selectBlog)
  const filteredNotes = useSelector(selectFilteredNotes)
  const pinnedPosts = useSelector(selectPinnedPosts)

  const { fullUrl } = useBlogUrl({ blog })
  const { track } = useAnalytics()

  useEffect(() => {
    track("blog page viewed")
  }, [])

  /**
   * This is used on all socials except farcaster. We want the 'subscribe'
   * buttion to be visible on the image.
   */
  const share_img = getShareImage({
    image: blog.logo_url || blog.user?.avatar_url,
    title: blog.name,
    description: getMetaDescription({ blog }),
    color: blog.primary_color || "",
  })

  /**
   * Generates the frame tags for the blog page, used when embedding this link in Farcaster, XMTP, etc.
   * @returns The frame tags for the blog page.
   */
  function FrameTags() {
    if (blog.user === undefined) {
      throw new Error(
        "Blog user is undefined. Cannot instantiate frame response generator without the blog user."
      )
    }

    /**
     * This is used on Farcaster, since there is already a subscribe button
     * at the protocol level, we hide it in the image.
     */
    const shareImgNoSubscribe = getShareImage({
      image: blog.logo_url || blog.user?.avatar_url,
      title: blog.name,
      description: getMetaDescription({ blog }),
      color: blog.primary_color || "",
      showSubscribeBtn: false,
    })

    const frameResponseGenerator = new FrameResponseGenerator({
      logger: consoleLogger,
      blog,
      blogOwnerUserId: blog.user.id,
    })

    // Generate the frame response for the blog page.
    const frameResponse = frameResponseGenerator.generateFrontEndFrameMetaTags({
      type: RESPONSES.HOMEPAGE_BLOG,
      image: shareImgNoSubscribe,
    })

    return (
      <>
        {frameResponse.map((tag) => (
          <meta
            key={tag.property}
            property={tag.property}
            content={tag.content}
          />
        ))}
      </>
    )
  }

  return (
    <div className="grow">
      <Head
        blog={blog}
        creator={blog?.social?.twitter}
        title={`${blog.name}`}
        share_img={share_img}
        description={getMetaDescription({ blog })}
        fullUrl={fullUrl}
        type="website"
      >
        <>
          <link
            title={`${blog.name} posts`}
            type="application/rss+xml"
            href={`/api/blogs/rss/${blog.url}`}
          />

          <FrameTags />
        </>
      </Head>
      <AllPostsSkeleton
        blog={blog}
        filteredNotes={sortByDate(filteredNotes, "publishedAt")}
        pinnedPosts={pinnedPosts || []}
        pageNum={pageNum}
        community={community}
        category={category}
      />
    </div>
  )
}

BlogPageComponent.getLayout = function getLayout(page: ReactElement) {
  return <PublicLayout>{page}</PublicLayout>
}

export default BlogPageComponent
