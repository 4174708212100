import { Note, Blog } from "@types"
import { generatePostPreview } from "./format"

// Handle images, no matter if they're stored using prod or staging.
/*
const patterns = [
  /.*\((https:\/\/storage.googleapis.com\/papyrus_images\/\w+)\)/,
  /.*(https:\/\/storage.googleapis.com\/papyrus_images\/\w+)/,
  /.*\((https:\/\/storage.googleapis.com\/dev_papyrus_images\/\w+)\)/,
  /.*(https:\/\/storage.googleapis.com\/dev_papyrus_images\/\w+)/,
]
*/

const patterns = [
  /\((https:\/\/storage.googleapis.com\/papyrus_images\/[\w+|\.]{1,100})\)/,
  /(https:\/\/storage.googleapis.com\/papyrus_images\/[\w+|\.]{1,100})/,
  /\((https:\/\/storage.googleapis.com\/dev_papyrus_images\/[\w+|\.]{1,100})\)/,
  /(https:\/\/storage.googleapis.com\/dev_papyrus_images\/[\w+|\.]{1,100})/,
]

export function getPreviewImage(
  note?: Pick<
    Note,
    "json" | "text" | "collectibleImgUrl" | "cover_img" | "cover_img_url"
  > | null,
  isNewsletter = false,
  optimized = true
): string | undefined {
  if (!note) return undefined

  // note.cover_img is new, note.cover_img_url is legacy
  const coverImgUrl = note.cover_img?.img?.src || note.cover_img_url

  if (coverImgUrl && optimized) {
    return `https://paragraph.xyz/_next/image?url=${encodeURIComponent(
      coverImgUrl
    )}&w=2048&q=100`
  }
  if (coverImgUrl) return coverImgUrl

  // If this is for a newsletter -> skip using the first image
  if (isNewsletter) return

  // if note is a collectible, use collectible image instead of first image in post
  if (note.collectibleImgUrl) {
    return note.collectibleImgUrl
  }

  const body = note.json || note.text

  if (!body) {
    return
  }

  // Find first image in note and use as preview image
  for (const p of patterns) {
    const matchObj = p.exec(body)

    if (matchObj?.[1] && optimized) {
      return `https://paragraph.xyz/_next/image?url=${encodeURIComponent(
        matchObj[1]
      )}&w=640&q=75`
    }
    if (matchObj?.[1]) return matchObj[1]
  }
}

type PreviewDescProps = {
  note?: Note | null
  blog?: Blog
}

export function getMetaDescription(props: PreviewDescProps): string {
  if (props.note) {
    return generatePostPreview(props.note)
  }

  return props?.blog?.summary || props?.blog?.about || ""
}
